window.createProjConfig = (region = 'test', project = 'test') => {
    const projConfig = window.projConfig = {
        gis: {
            layersFolder: getLayersFolder(project),
            tagsFolder: getTagsFolder(),
        },
        geo: {
            region: region
        },
        flightplanner: {
            missionFolder: getMissionsFolder(region)
        }
    };
    return projConfig;
};

function getMissionsFolder(region) {
    switch (region) {
        case 'test':
            return 'c04106713d2ca0f9a09cc8a86636e86ca';
        case 'krasnoyarsk':
            return 'bd93f7cd500e2802cf00df902041d0968';
        default:
            return 'c04106713d2ca0f9a09cc8a86636e86ca';
    }
}

function getLayersFolder(project) {
    switch (project) {
        case '2024':
            return 'c4e6883c491b8c0a0f22e9d1ebe329164';
        case 'lesfondgeo':
            return 'A4893701o18732o64343o59776o642536';
        default:
            return '';
    }
}

function getTagsFolder(project) {
    switch (project) {
        case '2024':
            return 'b5374b228f97c92e103d84cf663eb1dba';
        case 'lesfondgeo':
            return 'ba59d0f12058ea794929355913792deab';
        default:
            return '';
    }
}